document.getElementById('turista-newsletter-form-button').addEventListener("click", function(event) {
    event.preventDefault();
    grecaptcha.ready(function() {
        grecaptcha.execute('6Lfj6VkmAAAAADnaR4yFYuIs6AmwvCzItuU_s3C-', {action: 'submit'}).then(function(token) {
            let subscriber_email = document.getElementById('turista-newsletter-email').value
            let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!subscriber_email.match(validRegex)) {
                alert("L'indrizzo e-mail inserito non è valido");
                return false;
            }

            if (!document.getElementById('turista-newsletter-privacy-check').checked) {
                alert('Accettare la privacy è obbligatorio');
                return false;
            }


            let csrf_token = document.getElementById('newsletter_token').value;
            let formData = new FormData();
            formData.append('_token', csrf_token);
            formData.append('subscriber_email', subscriber_email);

            $.ajax({
                url: "/subscribe-to-newsletter",
                type: "POST",
                data: formData,
                contentType: false,
                processData: false,
                success: function(response) {
                    alert(response.message)
                },
                error: function() {
                    alert("Qualcosa è andato storto, riprova più tardi!")
                }
            });
        });
    });
});